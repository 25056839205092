<template>
  <div class="m-consenso" >
    <div class="m-consenso__container text-left mb-1 ">

        <p><strong>{{item.descrizione_consenso}}</strong></p>

      <b-form-radio-group
          :id="'radio-privacy-'+item.id"
          v-model="item.consenso"
          :name="'privacy'+item.id"
      >
        <b-form-radio value="S">{{
            $t("step.consenso_si")
          }}
        </b-form-radio>
        <b-form-radio  value="N">{{
            $t("step.consenso_no")
          }}
        </b-form-radio>
      </b-form-radio-group>
    </div>

  </div>
</template>

<script>
export default {
  name: "AppMdlConsenso",
  props: {
    item: {
      default: {

        tipo_consens: "",
        descrizione_consenso: "",
        consenso: null

      },
    },
  },
  data() {
    return {};
  },
  computed:{

  },

  methods: {},
};
</script>
