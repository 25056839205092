<template>
  <div id="dashboard">
    <div class="row no-gutters">
      <app-title title="mdl.home.title_landing" subtitle="mdl.home.subtitle_landing" ></app-title>
    </div>
    <div class="row no-gutters">

    </div>

    <div class="col-xl-8 offset-xl-2 col-10 offset-1">

      <div class="o-funtions">
        <div class="o-funtions__box">
          <app-service
              icon="icon-prenotazioni"
              serviceName="PRENOTING"
              :link="this.apriPrenota"
              anonym-state="2"
              type-button="true"
              :title="$t('funtions.prenotazione')"
          ></app-service>



        </div>
        <div class="o-funtions__box">
          <app-service
              icon="icon-referti"
              :link="this.apriReferti"
              type-button="true"
              :title="$t('funtions.all_ref')"
              service-name="REFERTI"
              anonym-state="2"
          ></app-service>


        </div>
      </div>
    </div>
    <AppMdlFooter></AppMdlFooter>
    <b-modal ref="noreferti-dialog"
             id="noreferti"
             centered
             modal-class="o-modal"
             content-class="o-modal__content d-block"
             header-class="o-modal__header"
             dialog-class="o-modal__dialog"
             body-class="o-modal__body o-modal__body--response "
             ok-title="ok-title"
             :cancel-disabled="true"
             :hide-footer="true"
             :hide-header-close="true"
             :hide-header="true"
             size="lg"
    >

      <p class="o-modal__body--title mt-5">
        <strong>Account non abilitato </strong>
      </p>
      <div class="mt-5">
        <p>Abilitare la visulizzazione dei referti:  </p>
      </div>
      <div class="row col-12 mt-2">
        <b-button variant="outline-primary" size="md" class="text-uppercase mt-3 col-5 " @click="annullaNoreferti()"
        >{{ $t('mdl.home.delete.annulla') }} </b-button>
      </div>
    </b-modal>

    <b-modal ref="unauth-dialog"
             id="unauth"
             centered
             modal-class="o-modal"
             content-class="o-modal__content d-block"
             header-class="o-modal__header"
             dialog-class="o-modal__dialog"
             body-class="o-modal__body o-modal__body--response "
             ok-title="ok-title"
             :cancel-disabled="true"
             :hide-footer="true"
             :hide-header-close="true"
             :hide-header="true"
             size="lg"
    >

      <p class="o-modal__body--title mt-5">
        <strong>{{$t('mdl.consensi.consenso_title')}} </strong>
      </p>
      <div class="mt-5">
        <p>{{ $t('mdl.consensi.esprimere') }}  </p>
        <p><strong>{{this.art_consenso}}</strong></p>

      </div>
      <div class="row col-12 mt-2">
        <b-button variant="outline-primary" size="md" class="text-uppercase mt-3 col-5 " @click="closeUnauth()"
        >{{ $t('mdl.home.delete.annulla') }} </b-button>
        <b-button variant="primary" size="md" class="text-uppercase mt-3  col-lg-5 offset-lg-2 col-12" @click="$router.push('/dettagliUtenza?tab=6')"
        >{{ $t('mdl.consensi.avanti') }} </b-button>
      </div>
    </b-modal>

    <b-modal ref="consensi-dialog"
             id="consensi"
             centered
             modal-class="o-modal"
             content-class="o-modal__content"
             header-class="o-modal__header"
             dialog-class="o-modal__dialog"
             body-class="o-modal__body o-modal__body--response pl-0 pr-0 "
             ok-title="ok-title"
             :cancel-disabled="true"
             :hide-footer="true"
             :hide-header-close="true"
             :hide-header="true"
             size="lg"
    >
      <p class="o-modal__body--title mt-5">
        <strong>{{$t("mdl.consensi.title")}}</strong>
      </p>
      <p>{{ $t("mdl.consensi.sub-title")}}</p>
      <div v-for="azienda in this.consensi" v-bind:key="azienda.nome_azienda" class="m-3 text-left">
        <p>{{$t("mdl.consensi.azienda")}} {{azienda.nome_azienda}}</p>
        <AppMdlConsenso v-for="cons in azienda.consensi" v-bind:key="cons.tipo_consenso" :item="cons"></AppMdlConsenso>
        <hr>
      </div>
      <h3 v-if="this.consensi.length==0">
        {{$t("mdl.consensi.no-consensi")}}
      </h3>
      <div class="mt-3">
        {{$t("mdl.consensi.download")}}<strong><a :href="$t('mdl.consensi.url_link')" >{{$t("mdl.consensi.download_link")}}</a></strong>
      </div>
      <div class="row">
      <div class="o-registration-collapse__error" v-if="privacyError!=null">{{
          $t("mdl.consensi.errors."+this.privacyError)
        }}</div>
      </div>
      <div class="row col-12 mt-2">
        <b-button variant="outline-primary" size="md" class="text-uppercase mt-3 col-lg-5 col-12 " @click="closePrivacy()"
        >{{ $t('mdl.consensi.indietro') }} </b-button>
        <b-button variant="primary" size="md" class="text-uppercase mt-3  col-lg-5 offset-lg-2 col-12" @click="inviaConsensi()"
        >{{ $t('mdl.consensi.invia') }} </b-button>
      </div>
    </b-modal>
  </div>

</template>

<script>

import AppTitle from "@/components/molecules/AppTitle";
import router from "@/router";
import AppService from "@/components/molecules/AppService";
import apiAxios from "@/libs/apiAxios";
import AppMdlFooter from "@/components/molecules/AppMdlFooter";
import AppMdlConsenso from "@/components/molecules/AppMdlConsenso";
import md5 from "md5";
export default {
  name: "dashboard",
  components: {AppMdlConsenso, AppMdlFooter,AppTitle, AppService},


  props: {},
  data() {
    return {

      loading:true,
      consensi:[],
      showConsensi:false,
      consensiOK:false,
      privacyError:null,
      action:null,
      art_consenso:''
    };
  },
  computed:{

    strong:function()
    {
      let userdata=this.$store.getters["dateUser/UserData"];
      if (userdata==null)
      {
        return false;
      }
      if ('role' in userdata)
      {
        return userdata.role===3;
      }
      return false;
    },
    payload: function ()
    {
      let userdata=this.$store.getters["dateUser/UserData"];


      console.log(userdata);

      /*    myServices.map(function (value) {
            let fld = value.name;
            servs[fld] = value;
          });*/
      userdata.services=[

      ];

      return userdata;
    }
  },
  created() {
    this.checkStatus();

  },

  methods: {

    checkSessionConsensi() {

      let data=sessionStorage.getItem("mdl_consensi_key");
      if (data==null)
        return false;
      data=JSON.parse(data);
      if (data==null) {
        sessionStorage.removeItem("mdl_consensi_key")
        return false;
      }
      const hash_i= md5(data.stuff+data.ts+"S3Cr3tMdl");

      const date= new Date();

      if (date.getMilliseconds() > data.ts)
      {
        sessionStorage.removeItem("mdl_consensi_key");
        return false;
      }

      if (hash_i===data.hash)
      {
        return true;
      }
      else {
        sessionStorage.removeItem("mdl_consensi_key");
        return false;
      }
    },
    markSessionConsensi(){
      const date= new Date();
      const ts_i=date.getMilliseconds()+(1000*60*20);
      const stuff_i= md5(date.getMilliseconds());
      const hash_i= md5(stuff_i+ts_i+"S3Cr3tMdl")
      const data=
          {
            stuff:stuff_i,
            ts:ts_i,
            hash:hash_i
          };

      sessionStorage.setItem("mdl_consensi_key",JSON.stringify(data))

    },

    openUnauth(){
      this.privacyError=null;
      this.$refs["unauth-dialog"].show();
    },
    closeUnauth(){
      this.$refs["unauth-dialog"].hide();
    },
    openPrivacy(){
      this.privacyError=null;
      this.$refs["consensi-dialog"].show();
    },
    closePrivacy(){
      this.$refs["consensi-dialog"].hide();
    },
    addAziendaConsensi(az)
    {
      let tmp=this.consensi;
      tmp.push(az);
      this.consensi=tmp;
      console.log(this.consensi);
    },
    checkConsensi(){
      if (this.checkSessionConsensi())
      {
       return true;
      }


      this.consensi=[];
      const _this=this;
      apiAxios.get('mdl/consensi').then(result=>{
            const data=result.data.MdlConsensiResult;

            let canGo=false;

            if (data.success)
            {
              _this.showConsensi=false;
              for(let i=0;i<data.consensi.data.length;i++)
              {
                const az=data.consensi.data[i];
                let azienda={
                  nome_azienda: az.azienda_consenso,
                  codice_azienda_consenso:az.codice_azienda_consenso,
                  id: 'AZ'+i,
                  consensi:[]
                };
                for (let c=0;c<az.consenso_credenziali_miocdi.length;c++)
                {
                  let cons=az.consenso_credenziali_miocdi[c];
                  cons.id='AZ'+i+'-CON'+c;
                  azienda.consensi.push(cons);
                  if (cons.consenso==null )
                    _this.showConsensi=true;

                  if (cons.tipo_consenso==="consenso credenziali mioCDI")
                  {
                    _this.art_consenso=cons.descrizione_consenso;
                  }

                  if (cons.tipo_consenso==="consenso credenziali mioCDI" && cons.consenso==="S")
                  {
                    canGo=true;
                  }
                }


                this.addAziendaConsensi(azienda);
              }
              if (_this.showConsensi) {
                _this.openPrivacy();
              }
              else {
                if (!canGo)
                {
                  _this.openUnauth();
                  return;
                }
                _this.markSessionConsensi();
                _this.checkConsensi();
                switch (_this.action)
                {
                  case "referti":
                    _this.apriReferti();
                    return;
                  case "prenota":
                    _this.apriPrenota();
                    return;
                }
                return;
              }
            }
            else {
              _this.consensi=[];
            }

          }
      )
          .catch(error=>{
            this.errorStr=error;
          })

      return false;
    },

    checkStatus(){

      apiAxios.get('mdl/status').then(result=>{
        const data=result.data.MdlStatusResult;
        let goBack=true;
        if (data.success)
        {
          if (data.data.enrolled) {
           goBack=false;
          }
          else {
            this.checked=true;
          }
        }
        else {
          this.hasError=true;
        }
        if (goBack)
        {
          this.$router.push('/mdl');
        }

      }

      )
          .catch(error=>{
            this.errorStr=error;
          })
    },
    apriReferti(){
      // Utente non validato con servizi
      /*if (this.payload.validation===0 || this.payload.validation===1){
        router.push('/unauthorized');
        return;
      }

      // Utente senza MPS o senza cod auth
      if (this.payload.bind!=1 || this.payload.authenticated===false){
        router.push('/dettagliUtenza?tab=6');
        return;
      }*/


      this.action='referti';
      if (!this.checkConsensi())
        return;




      sessionStorage.removeItem('singolo');
      sessionStorage.removeItem('referti');

      apiAxios.get('/mdl/referti/enabled')
          .then(response => {
            if (response.data.IsEnabledResult)
            {
              router.push('/referti?mdl=1')
            }
            else {
              //this.$bvModal.show("referto-otp");
              /*
              if (this.payload.services.REFERTI.pinAuth)
              {
                router.push('confermaAccessoApp?service=fatref&goto=referti');
              }
              else {
                router.push('confermaCodSms?service=fatref&goto=referti');
              }
              */
              router.push('/confermaAccessoApp?service=mdl&goto=referti&mdl=true');
            }
          })
          .catch(error=>{
            console.log(error);
          });
    },
    apriPrenota(){
      this.action='prenota';
      if (!this.checkConsensi())
        return;
     router.push('/mdl/reserve');
    },
    inviaConsensi(){
      this.privacyError=null;
     let go=true;
     for (var a=0;a<this.consensi.length;a++)
     {
       const azienda=this.consensi[a];
       for (var c=0;c<azienda.consensi.length;c++)
       {
         if (azienda.consensi[c].consenso===null)
         {
           go=false;
           break;
         }
       }
     }

     if (go)
     {
       const _this=this;
       let data=this.consensi;
       apiAxios.post('/mdl/consensi',data).then(response=>{
         const data=response.data.MdlConsensiResult;

         if (data.success)
         {
           _this.markSessionConsensi();
           _this.closePrivacy();
           switch(_this.action)
           {
             case "prenota":
               _this.apriPrenota();
               return;
             case "referti":
               _this.apriReferti();
               return;
           }
         }
         else {
           _this.privacyError="POST_ERROR";
         }
       })
     }
     else {
      this.privacyError="MISSING_CONSENSO"
     }
    }
  }
};
</script>
